.letter {
    font-weight: bold;
    font-size: 24px;
    color: black;
}

    .letter span {
        background-color: lightgray;
    }

    .letter .used {
        background-color: yellow;
    }

    .letter .inPosition {
        background-color: green;
    }